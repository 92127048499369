import { useEffect } from "react";
import Aos from "aos";

import CustomButton from "../../../shared_components/CustomButton";
import { TbBrandNextjs } from "react-icons/tb";

const HeroSection = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <div className="row mx-0 my-3 my-md-5 justify-content-center bootcamp">
        <div className="col-md-10 col-12 px-md-0 px-2 bootcamp-hero-section  d-flex justify-content-center">
          <div className="row mx-0 mt-0 justify-content-center align-items-center">
            <div className="col-md-7 col-12 px-md-0 px-1">
              <h1 className="text-md-left text-center">
                NextJs <span>Project Building Bootcamp</span>
              </h1>
              <h3 className="text-md-left text-center">
                Build, Learn, and Get Certified
              </h3>
              <p className="py-md-4 py-2 text-md-left text-center">
                Join a program that turns you into a real developer, not just a learner.
              </p>
              <div className="mb-4 mb-md-0 d-md-block d-flex justify-content-center">
                <div>
                  <a
                    href='https://api.whatsapp.com/send/?phone=917400898402&text=Hello%2C+I+am+interested+to+join+bootcamp&type=phone_number&app_absent=0'
                    target='_blank'
                    className="w-100"
                    rel='noopener noreferrer'
                  >
                    <CustomButton
                      buttonName=" Reserve Your Spot"
                      className="font-weight-bold col-md-6 col-12 p-3 rounded-pill shadow-lg px-md-5 px-4"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 px-md-0 px-2 py-md-0 d-md-flex justify-content-center">
              <div className="gap-3 card-section">
                <div className="card border-0 rounded-lg shadow-sm card-a">
                  <div className="card-body">
                    <h5 className="">📅 Next Batch Starts From</h5>
                    <h6>22/04/2025</h6>
                  </div>
                </div>
                <img
                  className="my-4 mx-auto img-fluid"
                  src="https://res.cloudinary.com/akshaydev1802/image/upload/v1741806164/iy7ccuyzox5wzxtklynt.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <TbBrandNextjs
          className="position-absolute d-md-block d-none next-icon"
          size={500}
          color=""
        />
      </div>
    </>
  );
};
export default HeroSection;
